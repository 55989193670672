import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, Link } from '@mui/material';
import SectionTitle from '../../../components/SectionTitle';
import CareersForm from '../../../components/forms/CareersForm';

const CareersRoot = styled('div')(({ theme }) => ({
    padding: '50px 0',
}));

const CareersDesc = styled(Typography)(({ theme }) => ({
    fontStyle: 'italic',
}));


const Careers = () => {
    return (
        <CareersRoot>
            <Container maxWidth="lg">
                <SectionTitle
                    title="Join Our Team"
                    subtitle="Current positions available"
                    align="left"
                />
                <CareersDesc variant="body1">
                    Feel free to submit your details for future positions
                </CareersDesc>
                <CareersForm />
            </Container>
        </CareersRoot>
    );
};

export default Careers;
